.register-wrapper {
    
    height: 100vh;
    width: 100vw;
    background-color: #171717;
  }

  .content-wrapper{
    position: absolute;
    top:45%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin: 0 0 20px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
  
  label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  
  input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f2f2f2;
    font-size: 16px;
    color: #333333;
    width: 100%;
  }
  
  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;
  }
  
  button[type="submit"]:hover:not([disabled]) {
    filter: brightness(1.1)
  }
  
  button[type="submit"]:disabled {
    background-color: rgb(206, 206, 206);
    color:black;
    opacity: .6;
    cursor: default;
  }
  