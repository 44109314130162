.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    margin:auto;
    margin-top:60px;
    margin-bottom:60px;
    min-width: fit-content;
    width: 40%;
    gap: 2rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
  }
  
  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .profile-header h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .profile-header img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .profile-initals {
    display: grid;
    place-content: center;
    font-size: 80px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #E2C5FF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-header input[type='file'] {
    margin-top: 1rem;
  }
  
  .profile-header button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .profile-header button:hover {
    background-color: #023e8a;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .profile-info h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom:0px;
  }
  
  .profile-info p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #555;
  }
  
  .profile-info p:first-of-type {
    margin-top: 1rem;
  }

  

  
  .profile-pic-upload-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .profile-pic-upload-label:hover {
    background-color: #023e8a;
  }
  
  .profile-pic-upload-label input[type="file"] {
    display: none;
  }