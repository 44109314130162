.floating-ufd-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #0072C6;
    color: #FFFFFF;
    z-index: 10;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    animation: slideInOut 6.5s cubic-bezier(.33,0,.67,1);
    animation-fill-mode: forwards;
}


@keyframes slideInOut {
    0% {
        transform: translate(0, 100%);
        opacity: 0;
    }

    10% {
        transform: translate(0, 0);
        opacity: 1;
    }

    90% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(0, 100%);
        opacity: 0;
    }
}