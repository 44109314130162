@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
  background-color: #121212;
  color: #ffffff;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.supportedFeatures {
  width: 80%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1.2em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: #1e1e1e;
}

.supportedFeatures th,
.supportedFeatures td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #343a40;
}

.supportedFeatures thead tr {
  background-color: #212121;
  color: #ffffff;
  text-align: left;
}

.supportedFeatures tbody tr {
  border-bottom: 1px solid #343a40;
}

.supportedFeatures tbody tr:nth-of-type(even) {
  background-color: #2a2a2a;
}

.supportedFeatures tbody tr:hover {
  background-color: #333333;
}

.supportedFeatures tbody tr:last-of-type {
  border-bottom: 2px solid #343a40;
}

.strong {
  font-weight: bold;
  color: #ffffff;
}

.supportedFeatures td {
  color: #b0bec5;
}

.supportedFeatures .thumbs-up {
  color: #4caf50;
}

.supportedFeatures .thumbs-down {
  color: #f44336;
}

.supportedFeatures .question-mark {
  color: #ff9800;
}
