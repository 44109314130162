.lottie-preview-collection {
    background:
        linear-gradient(-90deg, rgba(0, 0, 0, .05) 1px, transparent 1px),
        linear-gradient(rgba(0, 0, 0, .05) 1px, transparent 1px),
        linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
        linear-gradient(rgba(0, 0, 0, .04) 1px, transparent 1px),
        linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        linear-gradient(-90deg, #aaa 1px, transparent 1px),
        linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
        linear-gradient(#aaa 1px, transparent 1px),
        #f2f2f2;

    background-size:
        10px 10px,
        10px 10px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px,
        80px 80px;

    border-radius: 10px;
    height: 100%;
    display: grid;
    overflow: hidden;
    place-content: center;
    transition: all .5s cubic-bezier(.33, 0, .67, 1);

}



.lottie-preview-collection:hover {
    background-color: #c3c3c3;
}

.collection-bottom-wrapper {
    position: absolute;
    display: block;
    bottom: 0px;
    height: 20%;
    width: 100%;
    margin-top: 0px;
    background: linear-gradient(rgba(25, 25, 25, 0), rgba(25, 25, 25, .8));
}

.lottie-preview-collection-name {
    position: absolute;
    font-size: 14px;
    width: 100%;
    bottom: 12px;
    left: 0px;
    margin-top: 0px;
    height: fit-content;
    text-align: center;
}



.lottie-preview-collection-details {
    position: relative;
    font-size: 10px;
    margin-left: 0px;
    margin-top: 2px;
    opacity: .7;
    height: fit-content;
}

.collection-trash-wrapper {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    place-content: center;
    padding: 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);

}

.collection-trash-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.3);
}