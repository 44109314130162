#main-header{
    position: relative;
    display: flex;
    grid-template-columns: 200px 1fr;
    height: 50px;
    width:100vw;
    background-color:black;
}

#logo{
    font-size: 20px;
    color:white;
    margin-left: 12px;
    font-weight: 8G00;
    height: 100%;
    width: fit-content;
    display:grid;
    align-items: center;
}

.right-controls{
    position: absolute;
    right: 16px;
    height: 100%;
    display: flex;
    margin-top: 8px;
    gap: 10px;
     width: fit-content;
     place-content: center;
}


#header-comp-info-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.create-link-modal{
    position: fixed;
    top: 50px;
    right: 16px;
    height: auto;
    padding: 16px;
    width: 400px;
    z-index: 100;
    border-radius: 10px;
    color:black;
    background-color: #e7eaf1;
    transform-origin: top right;
    animation: scaleIn .4s cubic-bezier(0,0,0,1);
    transition: all .33s cubic-bezier(0,0,0,1);
}

.create-link-modal-h2{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

#attachments-container{
    position: fixed;
    top: 50px;
    right:170px;
    height: auto;
    padding: 0px;
    width: 250px;
    z-index: 100;
    border-radius: 10px;
    color:black;
    background-color: #e7eaf1;
    transform-origin: top right;
    overflow: hidden;
    animation: scaleIn .4s cubic-bezier(0,0,0,1);
    transition: all .33s cubic-bezier(0,0,0,1);
}

.dl-attachments-btn{
    background-color: var(--primary-color);
    padding: 6px;
    display: grid;
    place-content: center;
    border-radius: 10px;
    margin-left: 20px;
}

.attachment-container{
    display: flex;
    align-items: center;
    padding: 16px;

}

.attachment-container:hover{
    background-color: rgb(255, 255, 255);
}

.header-comp-name{
    color:white;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
}

.header-comp-subinfo{
    color:white;
    opacity:.6;
    font-size:10px;
}

.header-btn-primary{
    position: relative;
    display: flex;
    background-color: var(--primary-color);
    height: 32px;
    border-radius: 10px;
    align-items: center; 
    padding-left: 12px;
    padding-right: 16px;
    filter: brightness(.8);
    cursor: pointer;
}

.header-btn-primary:hover{
   
    filter: brightness(1)
}

#notepadContainer{
    position: fixed;
    top: 50px;
    right: 310px;
    height: auto;
    width: 320px;
    border-radius: 10px;
    padding: 14px;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    outline: none;
    transform-origin: top right;
    z-index: 10;
    background-color: #F4F4F4;
    transform: translateY(0px);
    animation: scaleIn .35s cubic-bezier(0,0,0,1);
}

#notepad{
    position: relative;
    min-height: 150px;
    max-height: 450px;
    width: inherit;
    padding: 0px;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    outline: none;
    border: none;
    overflow: auto;
    transform-origin: top right;
    background-color: #f4f4f4;
    transform: translateY(0px);
}

@keyframes scaleIn  {
    0%{
        transform: translateY(-6px) scale(.9);
        opacity: 0;
    }
    100%{
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}
#statusDD{
    background-color: black;
    color:white;
    text-align: right;
    width:'fit-content';
    border:none;
    font-family: "DM Sans";
    font-size: 14px;
    outline: none;
    font-weight: 500;
}
.header-btn-secondary1{
    position: relative;
    display: flex;
    /* background-color: var(--primary-color); */
    height: 34px;
    border-radius: 10px;
    align-items: center; 
    padding-left: 0px;
    padding-right: 16px;
}

.header-btn-secondary{
    position: relative;
    display: flex;
    /* background-color: var(--primary-color); */
    height: 34px;
    border-radius: 10px;
    align-items: center; 
    padding-left: 0px;
    padding-right: 16px;
}

.header-icon{
    height: 24px;
    
}

.header-text{
    color:white;
    font-size: 12px;
    font-weight: 800;
    margin-left: 8px;
}

.share-link-container{
    display: flex;
    position: relative;
    width: 400px;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
}

#link-share-btn-container{
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    margin-left:auto;
    margin-top: 16px;;
}

.copy-btn{
    display: grid;
    position: absolute;
    right: 6px;
    height: 42px;
    align-content:  right;
    place-content: center;
    background-color: var(--primary-color);
    padding-left: 28px;
    padding-right: 28px;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 10px;
    transition: all .33s cubic-bezier(.33,0,.67,1)

}

.eye-btn{
    display: grid;
    position: absolute;
    right: 0px;
    height: 42px;
    align-content:  right;
    place-content: center;
    padding-left: 28px;
    padding-right: 28px;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 10px;

}

.dbl-check{
    display: grid;
    position: absolute;
    right: 30px;
    height: 42px;
    align-content:  right;
    place-content: center;
    padding-left: 28px;
    padding-right: 28px;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 10px;

}

.share-link-preview{
    position: relative;
    display: grid;
    margin-top: 12px;
    font-size: 14px;
    padding-left: 0px;
    width: 220px;
    overflow: auto;
    opacity: .6;
    
}

.set-password-input{
    position: relative;
    display: grid;
    margin-top: 0px;
    font-size: 14px;
    padding-left: 0px;
    width: 220px;
    overflow: auto;
    opacity: .6;
    outline:none;
    border: none;
    background-color: transparent;
}

.input-subtext{
    margin-top: 4px;
}

.share-icon-modal{
    display: grid;
    position: relative;
    right: 6px;
    place-content: center;
    width: 60px;
    height: 40px;
    border-right: 2px solid rgba(0, 0, 0, 0.074);
    color: white;
    margin-top: 4px;
 
}

.share-link-type{
    display:flex;
    place-content: center;
    background-color: var(--primary-color);
    width: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 0px;
    border-radius: 20px;

}

.share-link-type-text{
    color:white;
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
    margin-right: 4px;
    margin-top: 2px;
}