.login-wrapper{
    position: absolute;
    top:45%;
    left:50%;
    transform: translate(-50%,-50%);
    animation: scaleIn_form .9s cubic-bezier(.25,0,0,1);
}

@keyframes scaleIn_form {
    0%{
        transform: translate(-50%,-50%) scale(.9);
        opacity: 0;
    }
    100%{
        transform: translate(-50%,-50%) scale(1);
        opacity: 1;
    }
}

.login-form {
    
    width: 400px;
    margin: auto;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: rgb(243, 243, 243);
    
  }
  
  .login-form h2 {
    text-align: center;
    margin-top: 0;
  }
  
  .login-form .form-group {
    margin-bottom: 1rem;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .login-form input[type="email"],
  .login-form input[type="password"] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button[type="submit"] {
    display: block;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button[type="submit"]:hover {
    background-color: #0069d9;
  }
  
  .login-form .error {
    color: red;
    margin-bottom: 1rem;
  }
  
  .register-link {
    text-align: center;
    margin-top: 1rem;
  }

  .reset-email-sent{
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }

  .forgot-password-link {
    text-align: center;
    margin-top: 1rem;
  }

  .forgot-password-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .register-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  
  /* For mobile devices */
@media only screen and (max-width: 600px) {
    .login-wrapper {
      top: 40%;
    }
    .login-form {
      max-width: 70%;
      padding: 1rem;
    }
    .login-form h2 {
      font-size: 1.5rem;
    }
    .login-form label {
      font-size: 0.8rem;
    }
    .login-form input[type="email"],
    .login-form input[type="password"] {
      font-size: 0.8rem;
      padding: 0.4rem;
      width: 94%;
      margin: auto;
    }
    .login-form button[type="submit"] {
      font-size: 1rem;
      padding: 0.4rem 1rem;
    }
    .register-link a,
    .forgot-password-link a {
      font-size: 0.8rem;
    }
  }
  