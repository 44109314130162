/* Container for the entire Getting Started content specific to Motion Spec.io */
.motionspec-container {
    margin: 0 auto;
    max-width: 900px;
    padding: 20px;
    background-color: #1e1e1e;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
  }
  
  /* Header styles for the main title with a Fluent UI teal-to-green gradient */
  .motionspec-header {
    margin-bottom: 20px;
    font-size: 42px;
    font-weight: 700;
    background: linear-gradient(90deg, #00b4db, #00c853);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Each section of the guide */
  .motionspec-section {
    margin-bottom: 40px;
  }
  
  /* Titles for each step or section with gradient and stylish font */
  .motionspec-stepTitle {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 22px;
    background: linear-gradient(90deg, #00b4db, #00c853);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* General paragraph text within each section */
  .motionspec-paragraph {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.8;
    color: #e0e0e0;
  }
  
  /* Placeholder styling for images with a dark theme */
  .motionspec-imagePlaceholder {
    width: 100%;
    height: 200px;
    background-color: #2b2b2b;
    border: 2px dashed #00c853;
    text-align: center;
    line-height: 200px;
    color: #888;
    margin-bottom: 20px;
  }
  
  /* Link styles with a vibrant accent color */
  a {
    color: #00e676;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  