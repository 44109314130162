#invite-modal-container {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 24px;
    width: 350px;
    max-width: 90%;
    box-sizing: border-box;
    text-align: center;
    transform-origin: bottom left;
    /* Set transform origin to bottom left */
    animation: scaleUp 0.4s cubic-bezier(.33, 0, .1, 1);
}

#invite-modal-container h3 {
    margin: 0 0 20px;
    font-size: 1.75rem;
    color: #333;
}

#invite-modal-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#invite-modal-container label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
}

.react-tagsinput {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 1rem;
  width: 100%;
}

.react-tagsinput-tag {
    background-color: #007bff;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px;
    display: flex;
    align-items: center;
}

.react-tagsinput-remove {
    margin-left: 8px;
    cursor: pointer;
}

.react-tagsinput-input {
    border: none;
    outline: none;
    padding: 5px;
    font-size: 1rem;
    flex: 1;
    min-width: 100px;
    /* Ensures the input field has a minimum width */
    width: auto;
    /* Allow the input field to auto size */
}

#invite-modal-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#invite-modal-container button.primary-btn {
    background-color: #007bff;
    color: white;
}

#invite-modal-container button.secondary-btn {
    background-color: #6c757d;
    color: white;
}

#invite-modal-container button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

#invite-modal-container .button-group {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 20px;
}

.error {
    color: red;
    margin-top: 10px;
}

@keyframes scaleUp {
    0% {
        opacity: 0;
        transform: scale(.5) translate(-15%, 15%);
    }

    100% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}