
.animation-timeline {
    position: relative;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
    width: 100%;
    background-color: var(--dark-002);
    content-visibility: auto;
   
  }
  
  .layer {
    display: flex;
    align-items: center;
    height: 40px;
    width: 200px;
 
   
  }

  #playhead{
    width:100%;
    margin-bottom: 2px;
    background-color: transparent;
}


  .layer-name {
    width: 150px;
    text-align: right;
    padding-right: 10px;
    font-weight: bold;
  }
  
  .layer-properties {
    position: relative;
    flex-grow: 1;
    height: 100%;
    
  }

.timeline-layer{
    background-color: rgb(38, 38, 38);
    margin-bottom: 6px;
    height: auto;
    padding: 10px;
    border-radius: 8px;
    margin-top: 6px;
   
}

.layer-parameter{
  font-weight: 500;
  margin-top: -2px;
  opacity: .75;
}

.keyframe-duration{
  position: relative;
  color:white;
  width: 60px;
  font-size: 12px;
  transform: translateY(-4px);
  
  text-align: left;
 /* white-space: nowrap; */

 
}

.timeline-layer-name{
    font-size: 12px;
    font-weight: 600;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
   
}
.timeline-anim-container{
  position: relative;
 padding: 0px;
 margin-top: 24px;
 margin-bottom: 32px;
 
} 

.keyframeline{
    position: relative;
    height: 6px; 
    border-radius:5px;
    
}

.keyframe-line-container{
  margin-left: 100px;
  margin-top: -12px;


}
  .property {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  }

  .keyframe{
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 0%;
    margin-top: -16px;
    margin-left: -4px;
    /* background-image: url('../Images/Icons/keyframe.svg'); */
    
    /* background-size: 10px 22px;
    background-position: -6px 0px;
    background-repeat: no-repeat; */


    top: -4px;
    opacity: 1;
  }

  .keyframeIcon{
    position: relative;
    width: 14px;
    height: 14px;


  }

  .keyframe:hover{
    opacity: 1;
  }
  
  .keyframes {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  
    display: flex;
  }
  

  