.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 30px;
    background-color: var(--dark-002);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
  
  .logo {
    font-size: 28px;
    font-weight: bold;
    color:white;
  }
  
  .nav {
    display: flex;
    align-items: center;
    color:white;
  }
  
  .nav a {
    margin-right: 20px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
  }
  
  .dropdown {
    position: relative;
  }
  
  .account-btn {
    margin-right: 0px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    text-transform: uppercase;
    background-color: #E2C5FF;

    padding: 6px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 160px;
    border-radius: 8px;
    padding: 8px 0;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }
  
  .dd-content {
    display: block;
    padding: 8px 8px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-left: 12px;
    text-decoration: none;
  }
  
  .dropdown-content a:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  