
 #pass-h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .password-protected-page{
    display: block;
    display: grid;
    place-content: center;
    height: 100vh;
    background-color: var(--dark-002);
  }
  #pass-form-p{
    font-size: 12px;
    text-align: center;
    margin-bottom: 16px;
    opacity: .7;
  }

  .password-protected-form-wrapper{
    animation: scaleIn 1s cubic-bezier(0,0,0,1)
  } 


  #pass-form{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 2.4rem;
    padding-top: 2em;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  #pass-input{
    padding:16px;
    width: 268px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.5rem;
  }
  #pass-form-subheading{
    font-family: 'DM Sans';
    font-size:18px;
    text-align: left;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .error-message {
    color: red;
    margin-top: 0.5rem;
  }
  

  
  #pass-sub-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  #pass-sub-button:hover {
    background-color: #3e8e41;
  }
  