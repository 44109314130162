.folder-container{
    display: grid;
    position: relative;
    place-content: center;
    background-color: #BBD2F3;
height: 300px;
    width: auto;
    padding-left:20px;
    padding-right:20px;
    border-radius: 8px;
}


.folder-icon{
    display: block;
    margin:auto;
    width: 80px;
    margin-top: 0px;
    margin-bottom: 12px;
    transform: translateX(10px)
}

.folder-name-input{
    font-size: 14px;
    background-color: #d1e1f7;
    font-weight: 700;
    width:100%;
    color: #0f2441;
    margin:auto;
    text-align: center;
    outline: none;
  
}
.avatars-container{
    position: absolute;
    display: flex;
    gap: 4px;
    width: 95%; 
    overflow: hidden;
    bottom: 10px;
    left: 10px;
}

.add-person-container{
    display: grid;
    place-content: center;
    width: 34px;
    height: 34px;
    background-color: var(--primary-color);
    border-radius: 100px;
}

#folder-dd-container{
    position: absolute;
    background-color: white;
    width: 100%;
    top: 36px;
    border-radius: 8px;
    left:0px;
    animation: slideDown_dd .3s cubic-bezier(0,0,0,1);
   
}

@keyframes slideDown_dd{
    0%{
        transform: translateY(-10px);
    }
    100%{
        transform: translateY(0px);
    }
}

.folder-name-input:focus{
    background-color: #e3ecf9;
    outline: 1px solid #9cc2f7;
}

.folder-type-pill{
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
    color:var(--primary-color);
    font-weight:500;
    background-color: transparent;
    border: 1px solid var(--primary-color)
}