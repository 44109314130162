/* SearchInput.css */

.search-input-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom:20px;
  }
  
  #search-input-collections{
    width: 22%;
    height: 18px;
    padding: 12px;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: rgb(171, 171, 171);
    background-color: var(--dark-004);
    outline: none;
  }
  
  .search-results {
    position: absolute;
    top: 50px;
    right: 16px;
    width: 22%;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--dark-009);
    color:var(--primary-white);
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1;
  }
  
  .search-results li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .search-results li:hover,
  .search-results li.active {
    background-color: var(--dark-000);
  }
  