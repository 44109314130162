/* Container for the entire best practices content */
.bp-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Main header for the page */
.bp-header {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #00e676;
}

/* Section title styling */
.bp-section-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #00c853;
}

/* Paragraph text styling */
.bp-section-text {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #e0e0e0;
}

/* Styling for warnings within sections */
.bp-section-warning {
  color: #ff5722;
  font-weight: 600;
}

/* Styling for additional notes within sections */
.bp-section-note {
  color: #00e676;
}
